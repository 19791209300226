import React from 'react';
import './ArrowIcon.scss';

export const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="Arrow-Icon"
      viewBox="0 0 583.234 583.234"
    >
      <path
        stroke-linecap="round" 
        stroke-linejoin="round"
        d="M298.045,504.992c-6.121,4.079-12.649,4.485-19.585,1.224s-10.404-8.976-10.404-17.136v-92.412
        H19.584c-5.712,0-10.404-1.836-14.076-5.508C1.836,387.487,0,383,0,377.697v-171.36c0-5.712,1.836-10.404,5.508-14.076
        s8.364-5.508,14.076-5.508h248.472V94.341c0-7.752,3.468-13.464,10.404-17.137c6.936-3.672,13.464-3.06,19.585,1.837
        l277.232,196.451c5.307,4.08,7.957,9.181,7.957,15.301v0.611c0,6.12-2.65,11.221-7.957,15.301L298.045,504.992L298.045,504.992z"
      />
    </svg>
  );
};
